import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import {
  addedMediaOnDynamic,
  setImageOverlayId,
  setMediaOnCarouselItem,
  setMediaOnSingle,
  setRecentlyCroppedImageId,
} from '@/editor/store/reducers/creative-step.slice';
import { IMediaLibraryDrawerState, setMediaLibraryDrawerState } from '@/editor/store/reducers/modals-drawers.slice';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const useImageList = (
  drawerState: IMediaLibraryDrawerState,
  propertyId: string,
  mediaLibraryData: IMediaLibraryImageModel[] | undefined,
  setTabValue: (id: number) => void,
  getMediaLibraryImages: (args: { propertyId: string }) => void
) => {
  const [uploadedImages, setUploadedImages] = useState<IMediaLibraryImageModel[]>([]);
  const [allImages, setAllImages] = useState<IMediaLibraryImageModel[]>([]);
  const [externalImages, setExternalImages] = useState<IMediaLibraryImageModel[]>([]);

  const recentlyCroppedImageId = useAppSelector((state) => state.creativeStepSliceReducer.recentlyCroppedImageId);
  const imageOverlayId = useAppSelector((state) => state.creativeStepSliceReducer.imageOverlayId);

  const dispatch = useAppDispatch();

  const addImageToCreative = (platform: PlatformType, image: IMediaLibraryImageModel) => {
    switch (drawerState.creativeType) {
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, image);
        break;
      case CreativeTypesEnum.DYNAMIC:
        dispatch(addedMediaOnDynamic({ media: image, platform }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(setMediaOnSingle({ media: image, platform }));
        break;
    }
  };

  const updateCarouselItemImageIfNeeded = (
    platformOption: PlatformType,
    media: IMediaLibraryImageModel | null
  ): void => {
    if (drawerState.carouselItemIndex !== undefined) {
      dispatch(
        setMediaOnCarouselItem({
          media,
          index: drawerState.carouselItemIndex,
          platform: platformOption as PlatformType,
        })
      );
    }

    if (media === null && drawerState.carouselItemIndex !== undefined) {
      toast.error('This images is already selected!');
    }
  };

  const addCroppedImageToSnapchat = (array: IMediaLibraryImageModel[], id: string): void => {
    addImageToCreative(
      AvailablePlatformsEnum.SNAPCHAT,
      array.find((image) => image['id'] === id) as IMediaLibraryImageModel
    );
    dispatch(setRecentlyCroppedImageId({ id: null }));
    handleCloseDrawer();
  };

  const addImageWithOverlay = (array: IMediaLibraryImageModel[], id: string): void => {
    addImageToCreative(
      drawerState.platform as PlatformType,
      array.find((image) => image['id'] === id) as IMediaLibraryImageModel
    );
    dispatch(setImageOverlayId({ id: null }));
    handleCloseDrawer();
  };

  const handleCloseDrawer = () => {
    dispatch(
      setMediaLibraryDrawerState({
        isOpened: false,
        creativeType: null,
        carouselItemIndex: undefined,
        platform: null,
      })
    );
  };

  useEffect(() => {
    let imageList = mediaLibraryData ? [...mediaLibraryData] : [];

    const uniqueInArr1 = imageList.filter((obj1) => !allImages.some((obj2) => obj1['id'] === obj2['id']));
    setAllImages(imageList);

    if (uniqueInArr1.some((image) => image['id'] === recentlyCroppedImageId)) {
      addCroppedImageToSnapchat(uniqueInArr1, recentlyCroppedImageId as string);
    }

    if (uniqueInArr1.some((image) => image['id'] === imageOverlayId)) {
      addImageWithOverlay(uniqueInArr1, imageOverlayId as string);
    }

    setExternalImages(imageList.filter((item) => item.originalUrl !== 'no_original_url' && item.sourceId === null));

    let uploadedImages: IMediaLibraryImageModel[] = imageList?.filter((item) => {
      if (item.originalUrl === 'no_original_url' || item.sourceId !== null) {
        return item;
      }
    });

    setUploadedImages(uploadedImages);
  }, [mediaLibraryData]);

  useEffect(() => {
    if (imageOverlayId !== null) {
      getMediaLibraryImages({ propertyId: propertyId as string });
    }
  }, [imageOverlayId]);

  return {
    uploadedImages,
    externalImages,
  };
};

export default useImageList;
