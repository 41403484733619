import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import {
  ICreativeFormsStatePerPlatform,
  setCreativeFormValueForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { setMediaLibraryDrawerState } from '@/editor/store/reducers/modals-drawers.slice';
import { useAppDispatch } from '@/store/hooks/redux';

interface IAftermarketingFormValues extends IBasicCreativeFormValues {
  message: string;
}

const useSetCreativeFormValues = (platform: PlatformType, creativeType: keyof ICreativeFormsStatePerPlatform) => {
  const dispatch = useAppDispatch();

  const setFormValues = (formValues: Partial<IAftermarketingFormValues>) => {
    if (formValues) {
      // since 'headline field in aftermarketing is called 'message'
      // the object has to be remapped
      const { message, ...rest } = formValues;
      const newFormValues = { ...rest, headline: message };

      dispatch(setCreativeFormValueForPlatform({ creativeType, platform, values: newFormValues }));
    }
  };

  return { setFormValues };
};

export default useSetCreativeFormValues;
