import { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import EmptyImageCard from '@/shared/components/platform-previews/previews/EmptyImageCard';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { ImageListItem, ImageListItemBar } from '@mui/material';
import React, { memo, useState } from 'react';
import { useLazyCreateImageOverlayQuery } from '@/editor/services/http/media-library.api';
import ImageBox from './ImageBox';
import useCheckIfSelectedImage from './hooks/useCheckIfSelectedImage';
import ConfirmImageDialog from './ConfirmImageDialog';
import useHandleImageClick from './hooks/useHandleImageClick';
import { IMediaLibraryDrawerState } from '@/editor/store/reducers/modals-drawers.slice';
import { ICreativeFormsStatePerPlatform, ICreativeStepState } from '@/editor/store/reducers/creative-step.slice';
import useSetCreativeFormValues from './hooks/useSetCreativeFormValues';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';

export interface IMediaLibraryImageItemProps {
  item: IMediaLibraryImageModel;
  queue: number;
  drawerState: IMediaLibraryDrawerState;
  creativeState: ICreativeStepState;
}

const MediaLibraryImageItem: React.FC<IMediaLibraryImageItemProps> = memo(
  ({ item, queue, drawerState, creativeState }): JSX.Element => {
    const [imageDialogOpen, setDialogOpen] = useState<boolean>(false);
    const [imageOverlayUrl, setImageOverlayUrl] = useState<string | undefined>('');
    const [aftermarketingFormData, setAftermarketingFormData] = useState<Partial<IBasicCreativeFormValues>>();

    const [createImageOverlay, { isFetching: overlayFetching }] = useLazyCreateImageOverlayQuery();
    const { isImageSelectedByPlatform, isImageSomeWhereSelected } = useCheckIfSelectedImage(
      drawerState,
      creativeState,
      item
    );
    const { handleImageClick } = useHandleImageClick(isImageSelectedByPlatform, drawerState, creativeState);
    const { setFormValues } = useSetCreativeFormValues(
      drawerState.platform!,
      drawerState.creativeType as keyof ICreativeFormsStatePerPlatform
    );

    const handleImageOverlay = (image: IMediaLibraryImageModel) => {
      setDialogOpen(true);
      createImageOverlay({ propertyFileId: image.id }).then((res) => {
        setImageOverlayUrl(res.data?.imageUrl);
        setAftermarketingFormData(res.data?.data);
      });
    };

    const onDialogClose = (confirm: boolean): void => {
      if (confirm) {
        setFormValues(aftermarketingFormData!);
      }

      setDialogOpen(false);
    };

    return (
      <>
        <ImageListItem sx={{ border: isImageSomeWhereSelected() ? '5px solid #000' : '5px solid transparent' }}>
          {item.preSignedUrl ? (
            <ImageBox
              image={item}
              platform={drawerState.platform!}
              creativeType={drawerState.creativeType! as CreativeType}
              handleImageClick={handleImageClick}
              handleImageOverlay={handleImageOverlay}
              isImageSelectedByPlatform={isImageSelectedByPlatform}
              isImageSomeWhereSelected={isImageSomeWhereSelected}
            />
          ) : (
            <EmptyImageCard />
          )}
          <ImageListItemBar
            sx={{
              display: { xs: 'none', md: 'inline' },
              p: 0,
              '& .MuiImageListItemBar-titleWrap': {
                padding: '4px 8px',
              },
            }}
            title={queue + 1 + ' - ' + item.width + 'x' + item.height}></ImageListItemBar>
        </ImageListItem>
        <ConfirmImageDialog
          open={imageDialogOpen}
          imageIsLoading={overlayFetching}
          imageUrl={imageOverlayUrl as string}
          onClose={onDialogClose}
        />
      </>
    );
  }
);

export default MediaLibraryImageItem;
