import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@/shared/components/Loader';
import { useAddProductMutation, useGetProductByIdQuery, useEditProductMutation } from '@/admin/services/http/products.api';
import CommonTextField from '@/shared/components/CommonTextField';
import { useTranslation } from 'react-i18next';

export interface ICreateEditProductsProps {
  isUpdating: boolean;
}

export interface IProductCreateEditFormValues {
  title: string;
  minimum: number;
  maximum: number;
  commission: number;
  status: boolean;
  overlay:boolean;
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  minimum: yup.number().required('Minimum is required'),
  maximum: yup.number().required('Maximum is required'),
  commission: yup.number().required('Commission is required'),
  status: yup.boolean().required('Status is required'),
});

const statusTypeOptions = [
  { value: true, title: 'Active' },
  { value: false, title: 'In-Active' }];

const CreateEdifProductForm: React.FC<ICreateEditProductsProps> = ({ isUpdating }): JSX.Element => {
  const { id } = useParams();
  const { t } = useTranslation()
  const [editProduct, { isLoading: editProductLoading }] = useEditProductMutation();
  const [addProduct, { isLoading: addProductLoading }] = useAddProductMutation();

  const {
    data: productData,
    isLoading: ProductDataLoading,
    isFetching: ProductDataFetching,
  } = useGetProductByIdQuery({ id: id ?? '' }, { skip: !isUpdating });

  const navigate = useNavigate();
  const initialValues: IProductCreateEditFormValues = {
    title: productData?.title || '',
    minimum:  productData?.minimum || 0,
    maximum:  productData?.maximum || 0,
    commission: productData?.commission || 0,
    status: productData?.status ?? true,
    overlay: productData?.overlay ?? false,
  };
  const handleSubmit = async (values: IProductCreateEditFormValues) => {
    try {
      productData ? await editProduct({ id: id!, values }) : await addProduct(values);
      navigate(-1);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const isLoadingFinished = (): boolean =>
    !ProductDataLoading && !ProductDataFetching && !ProductDataLoading && !ProductDataFetching;

  return (
    <div>
      {isLoadingFinished() ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IProductCreateEditFormValues) => {
            await handleSubmit(values);
          }}
          >
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                type="text"
                name="title"
                label={t('title')}
                value={values.title}
                onChange={handleChange}
                required
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <CommonTextField
                sx={{ marginTop: '15px' }}
                type="number"
                name="minimum"
                label={t('minimum')}
                value={values.minimum}
                onChange={handleChange}
                required
                error={Boolean(touched.minimum && errors.minimum)}
                helperText={touched.minimum && errors.minimum}
              />
              <CommonTextField
                sx={{ marginTop: '15px' }}
                type="number"
                name="maximum"
                label={t('maximum')}
                value={values.maximum}
                onChange={handleChange}
                required
                error={Boolean(touched.maximum && errors.maximum)}
                helperText={touched.maximum && errors.maximum}
              />
              <CommonTextField
                sx={{ marginTop: '15px' }}
                type="number"
                name="commission"
                label={t('commission')}
                value={values.commission}
                onChange={handleChange}
                required
                error={Boolean(touched.commission && errors.commission)}
                helperText={touched.commission && errors.commission}
              />
              <FormControl fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="status_label_id">{t('status')}</InputLabel>
                <Select
                  name="status"
                  labelId="status_label_id"
                  id="status_input_id"
                  value={values.status}
                  onChange={handleChange}
                  required
                  input={<OutlinedInput label={t('status')}></OutlinedInput>}
                  renderValue={(value: boolean) => (
                    <Box component="div">{(statusTypeOptions?.find((el) => el.value === value)?.title)}</Box>
                  )}
                >
                  {statusTypeOptions?.map(({ title, value }: any) => (
                    <MenuItem key={value} value={value}>
                      {(title)}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>
            <FormControlLabel
                sx={{ width:'100%', marginTop: '15px' }}
                control={
                  <Checkbox
                    name="overlay"
                    checked={values.overlay}
                    onChange={handleChange}
                  />
                }
                label={t('solgt')}
              />
              <Button
                sx={{ marginTop: '15px' }}
                type="submit"
                variant="contained"
                disabled={addProductLoading || editProductLoading}
                >
                {id ? <Typography>{t('update')}</Typography> : <Typography>{t('submit')}</Typography>}
              </Button>
            </form>
          )}
        </Formik>
        ):(
        <Loader /> 
        )}
    </div>
  );
};

export default CreateEdifProductForm;
