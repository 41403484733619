import { Card, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import CommonButton from '../../../../../shared/components/CommonButton';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { PlatformType } from '../../../constants/available-platforms';
import { IEditorOrderFormPropertiesBaseModel } from '../editor-order-form-properties-base.model';
import BasicCreativeForm from './BasicCreativeForm';
import { IBasicPreviewPropsModel } from './models/basic-preview-props.model';
import { ISingleCreativeFormValuesModel } from './models/single-creative-form-values.model';
import { useTranslation } from 'react-i18next';
import { IBasicCreativeFormValues } from './models/basic-creative-form-values.model';
import { SendOutlined } from '@mui/icons-material';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';
import { setcreativeSubmissionProgress } from '@/editor/store/reducers/creative-step.slice';

export interface IBasicSingleFormProps extends IEditorOrderFormPropertiesBaseModel<ISingleCreativeFormValuesModel> {
  basePreviews: React.FC<IBasicPreviewPropsModel>[];
  concretePreviews: React.FC[];
  fieldsRequired: boolean;
  platform: PlatformType;
  validationSchema: any;
}

const BasicSingleForm: React.FC<IBasicSingleFormProps> = ({
  initialValues,
  updating,
  basePreviews,
  concretePreviews,
  fieldsRequired,
  platform,
  validationSchema,
}): JSX.Element => {
  const singleReduxFormState = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState[platform].single
  );
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);
  const imageOverlayId = useAppSelector((state) => state.creativeStepSliceReducer.imageOverlayId);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema: validationSchema,
    onSubmit: async () => {},
  });

  const formikRef = useRef(formik);

  useEffect(() => {
    formikRef?.current.setFieldValue('media', singleReduxFormState?.media);
  }, [singleReduxFormState?.media]);

  useEffect(() => {
    if (imageOverlayId) {
      formikRef.current.setValues(singleReduxFormState!);
    }
  }, [imageOverlayId]);

  const { handleSubmit } = useFormSubmission({ formik, formSubmissionProgress, updating, platform });

  const handleUpdate = () => {
    dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
  };

  useEffect(() => {
    handleSubmit();
  }, [formSubmissionProgress.active]);

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          areFieldsRequired={fieldsRequired}
          platform={platform}
          initialValues={initialValues! satisfies IBasicCreativeFormValues}
          concretePreviews={concretePreviews}
          basePreviews={basePreviews}
        />
        <Grid container spacing={3}>
          {updating && (
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </div>
  );
};

export default BasicSingleForm;
