import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { AddPhotoAlternateOutlined, CheckCircleOutline } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import SnapChatIcon from '@/shared/icons/SnapChatIcon';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CreativeTypesEnum, { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import { useAppSelector } from '@/store/hooks/redux';
import { useTranslation } from 'react-i18next';

interface ImageBoxProps {
  image: IMediaLibraryImageModel;
  platform: PlatformType;
  creativeType: CreativeType;
  handleImageClick: (platform: PlatformType, image: IMediaLibraryImageModel) => void;
  handleImageOverlay: (image: IMediaLibraryImageModel) => void;
  isImageSomeWhereSelected: (image: IMediaLibraryImageModel) => boolean;
  isImageSelectedByPlatform: (platform: PlatformType) => boolean;
}

const ImageBox: React.FC<ImageBoxProps> = ({
  image,
  platform,
  creativeType,
  handleImageClick,
  handleImageOverlay,
  isImageSomeWhereSelected,
  isImageSelectedByPlatform,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const isOverlayAvailable = useAppSelector((state) => state.creativeStepSliceReducer.isOverlayAvailable);

  return (
    <Box
      onClick={() => {
        if (isImageSelectedByPlatform(platform!)) {
          handleImageClick(platform, image);
        }
      }}
      onMouseOut={() => setVisible(false)}
      onMouseOver={() => {
        !isImageSelectedByPlatform(platform!) ? setVisible(true) : null;
      }}>
      {isImageSomeWhereSelected(image) && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: '2',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.8)',
          }}>
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {isImageSelectedByPlatform(AvailablePlatformsEnum.META) && <FacebookIcon sx={{ fontSize: '55px' }} />}
            {isImageSelectedByPlatform(AvailablePlatformsEnum.SNAPCHAT) && <SnapChatIcon fontSize={'x-large'} />}
            {isImageSelectedByPlatform(AvailablePlatformsEnum.DELTA) && <NewspaperIcon sx={{ fontSize: '55px' }} />}
            {isImageSelectedByPlatform(AvailablePlatformsEnum.BIDTHEATRE) && (
              <img
                src="/images/bidtheatre/bidtheatre.png"
                alt="Bidtheatre"
                style={{ fontSize: 'large', width: '24px', height: '24px' }}
              />
            )}
            {isImageSelectedByPlatform(AvailablePlatformsEnum.BOARDS) && (
              <img
                src="/images/boards/boards_logo.jpg"
                alt="Boards"
                style={{ fontSize: 'large', width: '24px', height: '24px' }}
              />
            )}
          </Box>
        </Box>
      )}
      <Box
        onClick={() => {
          if (!isOverlayAvailable) {
            handleImageClick(platform, image);
          }
          setVisible(false);
        }}
        sx={{
          display: visible ? 'flex' : 'none',
          position: 'absolute',
          zIndex: '2',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.7)',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {creativeType === CreativeTypesEnum.SINGLE && isOverlayAvailable && (
          <Box
            sx={{
              color: '#fff',
              flexDirection: 'column',
              display: 'flex',
            }}>
            <Button
              onClick={() => {
                handleImageClick(platform, image);
                setVisible(false);
              }}
              sx={{
                my: 1,
                backgroundColor: '#000',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#e6952f',
                  color: '#FFF',
                },
              }}
              variant="contained"
              startIcon={<CheckCircleOutline />}>
              {t('imageSelect')}
            </Button>
            <Button
              onClick={() => {
                handleImageOverlay(image);
                setVisible(false);
              }}
              sx={{
                my: 1,
                backgroundColor: '#000',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#e6952f',
                  color: '#FFF',
                },
              }}
              variant="contained"
              startIcon={<AddPhotoAlternateOutlined />}>
              {t('overlaySelect')}
            </Button>
          </Box>
        )}
      </Box>
      <img width={'100%'} src={image.preSignedUrl} srcSet={image.preSignedUrl} alt={image.fileName} loading="lazy" />
    </Box>
  );
};

export default ImageBox;
