import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { AvailablePlatformsEnum } from '../../shared/constants/available-platforms';
import CreativeTypesEnum, { CreativeType } from '../../shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '../../shared/constants/editor-LS.constants';
import {
  CarouselItemKey,
  ICarouselCreativeFormValuesModel,
  ICarouselCreativeItemFormValuesModel,
} from '../../shared/components/forms/creatives/models/carousel-creative-form-values';
import { IDynamicCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/dynamic-creative-form-values.model';
import { ISingleCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/single-creative-form-values.model';
import { IVideoCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/video-creative-form.values.model';
import { IMediaLibraryVideoModel } from '@/shared/models/media-library/media-library-video.model';
import { PlatformType } from './../../shared/constants/available-platforms';
import { IBasicCreativeFormValues } from '../../shared/components/forms/creatives/models/basic-creative-form-values.model';

export interface ICreativeFormsStatePerPlatform {
  [CreativeTypesEnum.SINGLE]: ISingleCreativeFormValuesModel;
  [CreativeTypesEnum.DYNAMIC]: IDynamicCreativeFormValuesModel;
  [CreativeTypesEnum.CAROUSEL]: ICarouselCreativeFormValuesModel;
  [CreativeTypesEnum.VIDEO]: IVideoCreativeFormValuesModel;
}

export interface ICreativeFormsState {
  [AvailablePlatformsEnum.META]: ICreativeFormsStatePerPlatform;
  [AvailablePlatformsEnum.DELTA]: Partial<ICreativeFormsStatePerPlatform>;
  [AvailablePlatformsEnum.SNAPCHAT]: Partial<ICreativeFormsStatePerPlatform>;
  [AvailablePlatformsEnum.BIDTHEATRE]: Partial<ICreativeFormsStatePerPlatform>;
  [AvailablePlatformsEnum.BOARDS]: Partial<ICreativeFormsStatePerPlatform>;
}

export interface IValidPlatforms {
  [AvailablePlatformsEnum.META]: boolean;
  [AvailablePlatformsEnum.DELTA]: boolean;
  [AvailablePlatformsEnum.SNAPCHAT]: boolean;
  [AvailablePlatformsEnum.BIDTHEATRE]: boolean;
  [AvailablePlatformsEnum.BOARDS]: boolean;
}

export interface ICreativeSubmissionProgress {
  active?: Boolean;
  validPlatforms: IValidPlatforms;
  update?: boolean;
  platform?: PlatformType | null;
}

export interface ICreativeStepState {
  selectedCreativeType: CreativeType;
  creativeFormsState: ICreativeFormsState;
  creativeSubmissionProgress: ICreativeSubmissionProgress;
  recentlyCroppedImageId: string | null;
  imageOverlayId: string | null;
  isOverlayAvailable: boolean;
  selectedForSnapchat: IMediaLibraryImageModel[];
  isInitialized: boolean;
}

export type CreativeFormStateKey = keyof ICreativeFormsState;

const basicCreativeFormValues: IBasicCreativeFormValues = {
  headline: '',
  caption: '',
  description: '',
  link: '',
  ownershipType: '',
  areaInUsage: '',
  viewDate: '',
  stateDebt: '',
  priceQuote: '',
  propertyEstateType: '',
};

const prepareStatePerPlatform = (values: IBasicCreativeFormValues) => {
  return {
    [CreativeTypesEnum.SINGLE]: { ...values, media: null },
    [CreativeTypesEnum.VIDEO]: { ...values, media: null },
    [CreativeTypesEnum.DYNAMIC]: { ...values, creativeMedias: [] },
    [CreativeTypesEnum.CAROUSEL]: { ...values, creativeItems: [] },
  };
};

const prepareStateWithoutMedias = (values: IBasicCreativeFormValues) => {
  const preparedState = prepareStatePerPlatform(values);
  return {
    [AvailablePlatformsEnum.META]: preparedState,
    [AvailablePlatformsEnum.DELTA]: {
      [CreativeTypesEnum.SINGLE]: preparedState.single,
    },
    [AvailablePlatformsEnum.SNAPCHAT]: preparedState,
    [AvailablePlatformsEnum.BIDTHEATRE]: preparedState,
    [AvailablePlatformsEnum.BOARDS]: preparedState,
  };
};

const prepareSelectedCreativeTypeAfterRefresh = (): CreativeType => {
  const currentLSState = getValueFromLs(EDITOR_LS_CONSTANTS.SELECTED_CREATIVE_TYPE);

  if (currentLSState === null) {
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.SELECTED_CREATIVE_TYPE, CreativeTypesEnum.SINGLE);
    return CreativeTypesEnum.SINGLE;
  }

  return currentLSState as CreativeType;
};

const initialState: ICreativeStepState = {
  selectedCreativeType: prepareSelectedCreativeTypeAfterRefresh(),
  creativeFormsState: prepareStateWithoutMedias(basicCreativeFormValues),
  creativeSubmissionProgress: {
    active: false,
    validPlatforms: {
      [AvailablePlatformsEnum.META]: false,
      [AvailablePlatformsEnum.DELTA]: false,
      [AvailablePlatformsEnum.SNAPCHAT]: false,
      [AvailablePlatformsEnum.BIDTHEATRE]: false,
      [AvailablePlatformsEnum.BOARDS]: false,
    },
  },
  recentlyCroppedImageId: null,
  imageOverlayId: null,
  isOverlayAvailable: false,
  selectedForSnapchat: [],
  isInitialized: false,
};

const creativeStepSlice = createSlice({
  name: 'creative-step-slice',
  initialState,
  reducers: {
    setSelectedCreativeType(state: ICreativeStepState, action: PayloadAction<{ creativeType: CreativeType }>) {
      state.selectedCreativeType = action.payload.creativeType;
    },
    setFieldOfCreativeFormForPlatform(
      state: ICreativeStepState,
      action: PayloadAction<{
        platform: PlatformType;
        field: keyof IBasicCreativeFormValues;
        value: string | number | null | boolean;
        creativeType: keyof ICreativeFormsStatePerPlatform;
      }>
    ) {
      const { platform, field, value, creativeType } = action.payload;
      if (state.creativeFormsState[platform]) {
        (state.creativeFormsState[platform][creativeType] as IBasicCreativeFormValues) = {
          ...state.creativeFormsState[platform][creativeType],
          [field]: value,
        };
      }
    },
    setCreativeFormValueForPlatform(
      state: ICreativeStepState,
      action: PayloadAction<{
        creativeType: keyof ICreativeFormsStatePerPlatform;
        platform: PlatformType;
        values: IBasicCreativeFormValues;
      }>
    ) {
      // used in update mode
      const { platform, values, creativeType } = action.payload;
      (state.creativeFormsState[platform][creativeType] as IBasicCreativeFormValues) = values;
    },
    initiateCreativeFormsState(
      state: ICreativeStepState,
      action: PayloadAction<{ initialValues: IBasicCreativeFormValues }>
    ) {
      const { initialValues } = action.payload;
      state.creativeFormsState = prepareStateWithoutMedias(initialValues);
    },
    refreshCreativeFormState(state: ICreativeStepState) {
      state.creativeFormsState = prepareStateWithoutMedias(basicCreativeFormValues);
    },
    setMediaOnSingle(
      state: ICreativeStepState,
      action: PayloadAction<{
        media: IMediaLibraryImageModel;
        caption?: string;
        description?: string;
        link?: string;
        priceQuote?: string;
        platform: PlatformType;
      }>
    ) {
      const { media, caption, description, link, priceQuote, platform } = action.payload;
      state.creativeFormsState[platform][CreativeTypesEnum.SINGLE]!.media = media;
      // if (platform === 'bidtheatre') {
      //   state.creativeFormsState[platform][CreativeTypesEnum.SINGLE]!.caption = caption;
      //   state.creativeFormsState[platform][CreativeTypesEnum.SINGLE]!.description = description;
      //   state.creativeFormsState[platform][CreativeTypesEnum.SINGLE]!.link = link;
      //   state.creativeFormsState[platform][CreativeTypesEnum.SINGLE]!.priceQuote = priceQuote;
      // }
    },
    removeMediaOnSingle(
      state: ICreativeStepState,
      action: PayloadAction<{
        platform: PlatformType;
      }>
    ) {
      const { platform } = action.payload;
      state.creativeFormsState[platform][CreativeTypesEnum.SINGLE]!.media = null;
    },
    setCreativeItemsOnCarousel(
      state: ICreativeStepState,
      action: PayloadAction<{
        creativeItems: ICarouselCreativeItemFormValuesModel[];
        platform: PlatformType;
      }>
    ) {
      const { creativeItems, platform } = action.payload;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.CAROUSEL].creativeItems = creativeItems;
    },
    updateFieldOfCarouselItem(
      state: ICreativeStepState,
      action: PayloadAction<{
        platform: PlatformType;
        index: number;
        field: string;
        value: string;
      }>
    ) {
      const { platform, index, field, value } = action.payload;
      const typedField = field as CarouselItemKey;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      (typedCreativeFormState[CreativeTypesEnum.CAROUSEL].creativeItems[index][typedField] as string) = value;
    },
    addCarouselItem(state: ICreativeStepState, action: PayloadAction<{ platform: PlatformType; value: any }>) {
      const { platform, value } = action.payload;
      if (platform === AvailablePlatformsEnum.DELTA) {
        return;
      }

      state.creativeFormsState[platform][CreativeTypesEnum.CAROUSEL]!.creativeItems.push(value);
    },
    removeCarouselItem(state: ICreativeStepState, action: PayloadAction<{ platform: PlatformType; index: number }>) {
      const { platform, index } = action.payload;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.CAROUSEL].creativeItems.splice(index, 1);
    },
    setMediaOnCarouselItem(
      state: ICreativeStepState,
      action: PayloadAction<{
        media: IMediaLibraryImageModel | null;
        index: number;
        platform: PlatformType;
      }>
    ) {
      const { media, index, platform } = action.payload;
      if (platform === AvailablePlatformsEnum.DELTA) {
        return;
      }
      if (state.creativeFormsState[platform][CreativeTypesEnum.CAROUSEL]!.creativeItems[index]) {
        state.creativeFormsState[platform][CreativeTypesEnum.CAROUSEL]!.creativeItems[index].creativeMedia = media;
      }
    },
    setMediaOnDynamic(
      state: ICreativeStepState,
      action: PayloadAction<{
        creativeMedias: IMediaLibraryImageModel[];
        platform: PlatformType;
      }>
    ) {
      const { creativeMedias, platform } = action.payload;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.DYNAMIC].creativeMedias = creativeMedias;
    },
    addedMediaOnDynamic(
      state: ICreativeStepState,
      action: PayloadAction<{
        media: IMediaLibraryImageModel;
        platform: PlatformType;
      }>
    ) {
      const { media, platform } = action.payload;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.DYNAMIC].creativeMedias.push(media);
    },
    removeMediaOnDynamic(
      state: ICreativeStepState,
      action: PayloadAction<{
        media: IMediaLibraryImageModel;
        platform: PlatformType;
      }>
    ) {
      const { media, platform } = action.payload;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.DYNAMIC].creativeMedias = typedCreativeFormState[
        CreativeTypesEnum.DYNAMIC
      ].creativeMedias.filter((el) => el.id !== media.id);
    },
    setMediaOnVideo(
      state: ICreativeStepState,
      action: PayloadAction<{
        media: IMediaLibraryVideoModel;
        platform: PlatformType;
      }>
    ) {
      const { media, platform } = action.payload;
      const typedCreativeFormState = state.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.VIDEO].media = media;
    },
    removeMediaOnVideo(state: ICreativeStepState, action: PayloadAction<{ platform: PlatformType }>) {
      const typedCreativeFormState = state.creativeFormsState[
        action.payload.platform
      ] as ICreativeFormsStatePerPlatform;
      typedCreativeFormState[CreativeTypesEnum.VIDEO].media = null;
    },
    setcreativeSubmissionProgress(
      state: ICreativeStepState,
      action: PayloadAction<{ active: Boolean; update: boolean; platform: PlatformType | null }>
    ) {
      const { active, update, platform } = action.payload;
      state.creativeSubmissionProgress.active = active;
      state.creativeSubmissionProgress.update = update;
      state.creativeSubmissionProgress.platform = platform;
    },
    setValidPlatform(state: ICreativeStepState, action: PayloadAction<{ platform: PlatformType; isValid: boolean }>) {
      const { platform, isValid } = action.payload;
      state.creativeSubmissionProgress.validPlatforms[platform] = isValid;
    },
    resetValidPlatforms(state: ICreativeStepState) {
      state.creativeSubmissionProgress.validPlatforms = {
        [AvailablePlatformsEnum.META]: false,
        [AvailablePlatformsEnum.DELTA]: false,
        [AvailablePlatformsEnum.SNAPCHAT]: false,
        [AvailablePlatformsEnum.BIDTHEATRE]: false,
        [AvailablePlatformsEnum.BOARDS]: false,
      };
    },
    setRecentlyCroppedImageId(state: ICreativeStepState, action: PayloadAction<{ id: string | null }>) {
      state.recentlyCroppedImageId = action.payload.id;
    },
    setImageOverlayId(state: ICreativeStepState, action: PayloadAction<{ id: string | null }>) {
      state.imageOverlayId = action.payload.id;
    },
    setOverlayAvailable(state: ICreativeStepState, action: PayloadAction<boolean>) {
      state.isOverlayAvailable = action.payload;
    },
    setInitialized(state: ICreativeStepState, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
  },
});

export const {
  setSelectedCreativeType,
  setFieldOfCreativeFormForPlatform,
  initiateCreativeFormsState,
  setCreativeFormValueForPlatform,
  refreshCreativeFormState,
  setCreativeItemsOnCarousel,
  addCarouselItem,
  removeCarouselItem,
  updateFieldOfCarouselItem,
  setMediaOnCarouselItem,
  setMediaOnDynamic,
  setMediaOnSingle,
  addedMediaOnDynamic,
  removeMediaOnDynamic,
  removeMediaOnSingle,
  setMediaOnVideo,
  removeMediaOnVideo,
  setcreativeSubmissionProgress,
  setValidPlatform,
  resetValidPlatforms,
  setRecentlyCroppedImageId,
  setImageOverlayId,
  setOverlayAvailable,
  setInitialized,
} = creativeStepSlice.actions;

export default creativeStepSlice.reducer;
