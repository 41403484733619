import { useUploadImageMutation } from '@/editor/services/http/media-library.api';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { setImageOverlayId } from '@/editor/store/reducers/creative-step.slice';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { Close, CloudUpload, Image, UploadFileRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface SimpleDialogProps {
  open: boolean;
  imageUrl: string;
  imageIsLoading: boolean;
  onClose: (confirm: boolean) => void;
}

const ConfirmImageDialog: React.FC<SimpleDialogProps> = ({ open, imageUrl, imageIsLoading, onClose }): JSX.Element => {
  const [uploadImage, { isLoading, isError, isSuccess }] = useUploadImageMutation();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const propertyId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)
    ? getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)
    : useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  const handleClose = (confirm: boolean) => {
    onClose(confirm);
  };

  const handleConfirm = async () => {
    const img = new window.Image();
    img.src = imageUrl;

    fetch(imageUrl)
      .then((r) => r.blob())
      .then((res) => {
        uploadImage({
          extension: 'jpg',
          width: img.width,
          height: img.height,
          propertyId: propertyId as string,
          file: res as File,
        })
          .unwrap()
          .then((res) => {
            dispatch(setImageOverlayId({ id: res.propertyFileId }));
            handleClose(true);
          });
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid
        container
        p={2}
        sx={{
          minWidth: '560px',
          minHeight: '460px',
        }}>
        {imageIsLoading ? (
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                variant="indeterminate"
                size={40}
                sx={{
                  color: '#000',
                  position: 'absolute',
                  top: -7,
                  left: -8,
                  zIndex: 1,
                }}
              />
              <Image sx={{ fontSize: 25 }} color="secondary" />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <img src={imageUrl} width={'100%'} />
            </Grid>
            {isLoading ? (
              <Grid item xs={12} my={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Box sx={{ position: 'relative' }}>
                  <CircularProgress
                    variant="indeterminate"
                    size={40}
                    sx={{
                      color: '#000',
                      position: 'absolute',
                      top: -7,
                      left: -8,
                      zIndex: 1,
                    }}
                  />
                  <UploadFileRounded sx={{ fontSize: 25 }} color="secondary" />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Button onClick={() => handleClose(false)} variant="contained" color={'error'} startIcon={<Close />}>
                  {t('cancel')}
                </Button>
                <Button onClick={handleConfirm} variant="contained" color={'secondary'} startIcon={<CloudUpload />}>
                  {t('create')}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default ConfirmImageDialog;
